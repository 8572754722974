import { useState, useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { NumberToWords, CalculateTotal } from "../components/Utils";
import axios from "../axios/index";

import Table from "../components/Table/Table";
import "./InvoiceTemplate.scss";
import "./Print.scss";

const DcInvoice = () => {

    const { id } = useParams();

    const printContent = useRef(null);
    const [customerDetail, setCustomerDetail] = useState([]);
    const [itemsDetail, setItemsDetail] = useState([]);

    const NetQty = CalculateTotal(itemsDetail, "qty");

    const [emptyRow, setEmptyRow] = useState([{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    }]);

    const getSubGroupName = async () => {
      await axios
        .get(`DeliveryChallan/print/${id}`)
        .then((response) => response.data)
        .then((data) => {
          
          setCustomerDetail(data.detail);        
          setItemsDetail(data.items);        
          // window.print();

            let row=9;
            let rowaval=data.items.length();
            if(rowaval>9)
            {
              row=0;
            }
            else{
              row=9-rowaval;
            }
            let emprow=[];
            console.log(row)
            for(let i=0;i<=row;i++){
              emprow.push({
                priority_id: i,
              });      
              
            }            
            setEmptyRow([...emprow]);
        });
    };


  
  useEffect(() => {
    getSubGroupName();    
  }, []);
  
  
  
  
  return (
    <>
      <div
        className="row justify-content-center invoice-template"
      // ref={printContent}
      >
        <div className=" print-container" style={{width:"21cm",height:"15.2cm",fontSize:"12px",fontWeight:"600",lineHeight:"14px",fontFamily:"sans-serif"}}>
          <div className="card print-content" id="demo">
            {/* Bill-Header */}
            <div className="card-header py-3 pt-3 pb-1" style={{width:"100%",height:"2.5cm"}}>
              <div className="d-sm-flex">
                <div className="flex-grow-1" style={{ textAlign: "center" }}>
                  <h3 style={{fontWeight:"800",color:"black"}}>Packing Slip</h3>
                  <div className="mt-sm-2">
                    <p className=" text-uppercase ">
                    Vari Fabrics
                    </p>
                    
                    <p
                      className=" mb-1 "
                      id="address-details"
                    >
                      Erode - 638102
                    </p>
                    
                  </div>
                  
                </div>
              </div>
            </div>

            {/* Bill-Customer-Detail */}
            <div className="card-body p-0 border-top border-bottom" style={{width:"100%",height:"2.5cm"}}>
              <div className="row g-0" style={{height:"100%"}}>
                <div className="col-sm-6 p-2 border-right">
                  <p className=" text-uppercase  mb-2 fs-11">
                    To,
                  </p>
                  <div className="ps-3">
                    <p className=" fs-11" id="billing-name">
                      {customerDetail.name}
                    </p>
                    <p className=" fs-11" id="billing-address-line-1">
                      {customerDetail.address}
                    </p>
                    
                    <p className=" fs-11">
                      <span>Phone No: </span>
                      <span id="billing-phone-no">
                        {customerDetail.mobile}
                      </span>
                    </p>
                    <p className=" fs-11 mb-0">
                      <span>GSTIN: </span>
                      <span id="billing-tax-no">{customerDetail.gstin}</span>
                    </p>
                    {/* <p className=" fs-11 mb-0">
                      <span>STATE CODE: </span>
                      <span id="billing-tax-no">29</span>
                    </p> */}
                  </div>
                </div>
                <div className="col-sm-6" style={{height:"100%",borderLeft:"1px solid black"}}>
                  <div className="p-2 d-flex align-items-center border-bottom">
                    <div className="col-sm-6">
                      <p className=" mb-0 fs-12">
                        <span className="">Dc No</span> : {customerDetail.reference_no}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <p className=" mb-0 fs-12">
                        <span className="">DATE</span> :{" "}
                        {moment(customerDetail.date).format("DD-MM-YYYY")}
                      </p>
                    </div>
                  </div>
                  <div className="p-2" >
                    <p className="  mb-2 fs-11">
                      Trans :
                    </p>
                    <p className=" fs-11" id="shipping-name">
                      {customerDetail.dispatch_through}
                    </p>
                    <p className=" fs-11 mb-0">
                      <span>Place: </span>
                      <span id="billing-tax-no">{customerDetail.gstin}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Bill-LR */}
            

            {/* Bill-Item */}
            <div className="card-body p-0 border-bottom" style={{width:"100%",height:"7cm"}}>
              
              <div className="row g-0 m-0 align-items-center">
                    <div style={{width:"5%",fontSize:"11px",borderBottom:"1.5px solid black",borderRight:"1.5px solid black",height:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}> No</div>
                    <div style={{width:"80%",fontSize:"11px",borderBottom:"1.5px solid black",borderRight:"1.5px solid black",height:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>Description of Goods</div>
                    <div style={{width:"15%",fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>Qty</div>
              </div>
              

              {itemsDetail.map((item,index) =>{
                // console.log(index)
                 return (
                    <>
              <div className="row g-0 m-0 align-items-center" style={{minHeight:"0.5cm"}}>
                    <div style={{width:"5%",fontSize:"11px",borderRight:"1.5px solid black",minHeight:"0.5cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                      {index+1}
                    </div>
                    <div style={{width:"80%",fontSize:"11px",borderRight:"1.5px solid black",minHeight:"0.5cm",display:"grid",textAlign:"left",alignItems:"center"}}>
                      {item.name}
                    </div>
                    <div style={{width:"15%",fontSize:"11px",minHeight:"0.5cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                    {Math.abs(item.qty)}
                    </div>
                    {/* <div style={{width:"21%",fontSize:"11px",height:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>IGST</div> */}
              </div>
              </>
               );
              })
              }
              


              {emptyRow.map((row,index) =>{
                
                 return (
                    <>
                    
                      <div className="row g-0 m-0 align-items-center" style={{minHeight:"0.5cm"}}>
                            <div style={{width:"5%",fontSize:"11px",borderRight:"1.5px solid black",minHeight:"0.5cm",display:"grid",textAlign:"center",alignItems:"center"}}>

                            </div>
                            <div style={{width:"80%",fontSize:"11px",borderRight:"1.5px solid black",minHeight:"0.5cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                              
                            </div>
                            
                            <div style={{width:"15%",fontSize:"11px",minHeight:"0.5cm",display:"grid",textAlign:"center",alignItems:"center"}}>

                            </div>

                      </div>

                    </>
                );
              })
              }

                      <div className="row g-0 m-0 align-items-center" style={{minHeight:"1cm",borderTop:"1.5px solid black"}}>
                            
                            <div style={{width:"85%",fontSize:"11px",borderRight:"1.5px solid black",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                              Total
                            </div>
                            
                            <div style={{width:"15%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                            {NetQty}
                            </div>

                      </div>

            </div>
            <div className="row g-0 m-0 align-items-center" style={{minHeight:"1cm"}}>
                            
                  <div style={{width:"85%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"left",alignItems:"center"}}>
                    Checked by
                  </div>
                  
                  <div style={{width:"15%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                    For VARI FABS
                  </div>

            </div>
            {/* Bill-Amount */}
            
            
          </div>
        </div>
      </div>
    </>
  );
};

export default DcInvoice;
