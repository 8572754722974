import React, { useState } from "react";
import routes from "../routes";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { authLogout } from "../STORE/Action/Auth";

const Header = () => {
  const [open, setOpen] = useState(true);
  const [lowerWidthOpen, setLowerWidthOpen] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const hamburgerIconClick = () => {
    let link = document.querySelector("#header_css");
    let target = document.documentElement.getAttribute("data-sidebar-size");
    if (window.innerWidth > 767) {
      if (target === "lg") {
        setOpen(link.setAttribute("data-sidebar-size", "sm"));
        document.querySelector("#scrollbar").classList.remove("scroller");
      } else {
        setOpen(link.setAttribute("data-sidebar-size", "lg"));
        document.querySelector("#scrollbar").classList.add("scroller");
      }
      setOpen(!open);
    } else {
      setLowerWidthOpen(!lowerWidthOpen);
    }
  };

  let user = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    function handleResize() {
      var e = window.innerWidth;
      if ("vertical" === document.documentElement.getAttribute("data-layout")) {
        if (e < 1025 && e > 767) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.body.classList.remove("twocolumn-panel");
          document.documentElement.setAttribute("data-sidebar-size", "sm");
        } else if (1025 < e) {
          document.body.classList.remove("vertical-sidebar-enable");
          document.body.classList.remove("twocolumn-panel");
          document.documentElement.setAttribute("data-sidebar-size", "lg");
        } else {
          if (e <= 767) {
            document.querySelector(".hamburger-icon").classList.add("open");
            document.body.classList.add("twocolumn-panel");
            document.documentElement.setAttribute("data-sidebar-size", "lg");
          }
        }
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const closeSidemenu = () => {
    if (document.body.classList.contains("vertical-sidebar-enable")) {
      document.body.classList.remove("vertical-sidebar-enable");
      setLowerWidthOpen(false);
    }
  };

  useEffect(() => {
    // document.body.addEventListener("click", closeSidemenu);
    document
      .querySelector(".vertical-overlay")
      .addEventListener("click", closeSidemenu);

    setTimeout(() => {
      document
        .querySelector("#dashboard-item")
        .addEventListener("click", closeSidemenu);
    }, 200);

    return function cleanup() {
      window.removeEventListener("click", closeSidemenu);
    };
  }, []);

  useEffect(() => {
    if (lowerWidthOpen) {
      document.body.classList.add("vertical-sidebar-enable");
    }
  }, [lowerWidthOpen]);

  useEffect(() => {
    var hasNumber = /\d/;
    let reversedPathname = location.pathname;
    if (hasNumber.test(location.pathname)) {
      reversedPathname = `${location.pathname.replace(/[0-9]/g, "")}:id`;
    }

    let currentRoute = routes.find((route) => route.path === reversedPathname);

    let user = JSON.parse(localStorage.getItem("userInfo"));

    let rightArray = [];
    if (user?.user_right) {
      rightArray = user?.user_right.split(",");
    }
    rightArray.push("dashboard");

    if (
      currentRoute &&
      !rightArray.find((right) => right === currentRoute.securityCode)
    ) {
      if (user) {
        history.push("/dashboard");
      } else {
        history.push("/login");
      }
    }
  }, [location.pathname, history]);

  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(authLogout());
    localStorage.removeItem("userInfo");
    history.push("/login");
  };

  const hamburgericon = `${!open ? "hamburger-icon open" : "hamburger-icon"}`;

  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
              id="topnav-hamburger-icon"
              onClick={hamburgerIconClick}
            >
              <span className={hamburgericon}>
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>

          <div className="d-flex align-items-center">
            {/* <div className="dropdown topbar-head-dropdown ms-1 header-item">
              <button
                type="button"
                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="bx bx-category-alt fs-22"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
                <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                  <div className="row align-items-center">
                    <div className="col">
                      <h6 className="m-0 fw-semibold fs-15"> Quick Access </h6>
                    </div>
                  </div>
                </div>

                <div className="p-2">
                  <div className="row g-0">
                    <div className="col">
                      <Link
                        className="dropdown-icon-item"
                        to="/reports/voucher/statement"
                      >
                        <img
                          src="assets/images/quickaccess/Ledger.png"
                          alt="Ledger"
                        />
                        <span>Ledger List</span>
                      </Link>
                    </div>
                    <div className="col">
                      <a
                        className="dropdown-icon-item"
                        href="#/reports/weeklyfinancial"
                      >
                        <img
                          src="assets/images/quickaccess/WeeklyReport.png"
                          alt="bitbucket"
                        />
                        <span>Financial Report</span>
                      </a>
                    </div>
                    <div className="col">
                      <a
                        className="dropdown-icon-item"
                        href="#/reports/pricelist"
                      >
                        <img
                          src="assets/images/quickaccess/PriceList.png"
                          alt="dribbble"
                        />
                        <span>Price List</span>
                      </a>
                    </div>
                    <div className="col">
                      <a
                        className="dropdown-icon-item"
                        href="#/reports/outstanding/balancesheet"
                      >
                        <img
                          src="assets/images/quickaccess/balance-sheet.png"
                          alt="balance-sheet"
                        />
                        <span>Balance Sheet</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button
                type="button"
                className="btn"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle header-profile-user"
                    src="assets/icons.png"
                    alt="Header Avatar"
                  />
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text text-uppercase">
                      {user?.user_name}
                    </span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">Welcome {user?.user_name}!</h6>
                <a className="dropdown-item" href="/" onClick={logoutHandler}>
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
