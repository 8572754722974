import { useRef, useState, useEffect } from "react";

const ButtonLoader = (defaultText = "Save", loadingText = "Loading...") => {
  const [isLoading, setLoading] = useState(false);
  const element = useRef(null);

  useEffect(() => {
    if (isLoading) {
      element.current.disabled = true;
      element.current.innerHTML =
        '<span class="spinner-border flex-shrink-0"></span> ' + loadingText;
    } else {
      element.current.disabled = false;
      element.current.innerHTML = defaultText;
    }
  }, [isLoading]);

  return [element, setLoading];
};

export default ButtonLoader;
