import React from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Content from '../components/Content'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'

const BaseLayout = ({ children }) => {
  const history = useHistory();

  let user = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    if (!user) {
      history.push("/login")
    }
  }, [user, history]);

  return (
    <div id="layout-wrapper">
      <Header />
      <Sidebar />
      <div className="vertical-overlay"></div>
      <Content>{children}</Content>
    </div>
  )
}

export default BaseLayout