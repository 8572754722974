import React, { useState } from "react";
import { Table, Empty } from "antd";
import "./Table.scss";

const TableContainer = ({ columns, data, scroll, pagination, onClick, rowClassName, inline }) => {
  const [page, setPage] = useState(1);

  let tableColumns = [];

  columns?.map((column) => {
    tableColumns.push({ ...column, className: `${column?.className ? column.className : ""} ${inline ? "p-5" : "p-10"}` })
  })

  let tableData = [];

  data?.map((info, index) => {
    tableData.push({ ...info, page: page, tableKey: index })
  })

  return (
    <div className={`${inline ? "custom-inline-table-container" : "custom-table-container"}`}>
      <Table
        rowKey="tableKey"
        locale={{ emptyText: inline ? <></> : <Empty description="No Data Found" className="empty-margin" /> }}
        className="custom-table"
        columns={tableColumns}
        dataSource={tableData}
        bordered
        pagination={pagination ? {
          onChange(current) {
            setPage(current);
          }
        } : false}
        scroll={scroll}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => { onClick && onClick(event, record) }, // click row
            onDoubleClick: event => { }, // double click row
            onContextMenu: event => { }, // right button click row
            onMouseEnter: event => { }, // mouse enter row
            onMouseLeave: event => { }, // mouse leave row
          };
        }}
        rowClassName={rowClassName}
      />
    </div>
  );
};

export default TableContainer;
