import { AUTH_LOGIN, AUTH_LOGOUT } from "../Action/Auth";

const initialState = {
    status: null,
    message: "",
    user_id: "",
    user_name: "",
    user_right: "",
    financial_year: "",
    branch: null,
    branch_name: ""
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_LOGIN:
            return {
                ...state,
                ...action.userInfo,
            };
        case AUTH_LOGOUT:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};

export default authReducer;
