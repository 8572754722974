import { useState, useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { NumberToWords } from "../components/Utils";
import axios from "../axios/index";

import Table from "../components/Table/Table";
import "./InvoiceTemplate.scss";
import "./Print.scss";

const ForwardInvoice = () => {

    const { id } = useParams();

    const printContent = useRef(null);
    const [billDetail, setBillDetail] = useState([]);
    const [customerDetail, setCustomerDetail] = useState([]);
    const [itemsDetail, setItemsDetail] = useState([]);
    const [emptyRow, setEmptyRow] = useState([{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    }]);

    const getSubGroupName = async () => {
      await axios
        .get(`sales/forwardInvoice/${id}`)
        .then((response) => response.data)
        .then((data) => {
          
          setBillDetail(data.detail);        
          setCustomerDetail(data.customer);        
          setItemsDetail(data.items);        
          // window.print();
          // forwardInvoice
        });
    };

    const getInvoiceDetail = async () => {
    // await axios
    //   .get(`item/viewItemSubGroup/${id}`)
    //   .then((response) => response.data)
    //   .then((data) => {
    //     setCustomerDetail(data.sub_group);        
    //   });

    let row=10;
    let rowaval=itemsDetail.length();
    if(rowaval>10)
    {
      row=0;
    }
    else{
      row=10-rowaval;
    }
    let emprow=[];

    for(let i=0;i<=10;i++){
      emprow.push({
        product_id: i,
        product_name: "s",
        qty: 0,
      });      
    }

    setEmptyRow(
      [ ...emptyRow,emprow]
     );
      console.log(emprow)

  };

  
  useEffect(() => {
    getSubGroupName();    
  }, []);
  
  useEffect(() => {
    getInvoiceDetail();    
  }, []);
  
  
  
  return (
    <>
      <div
        className="row justify-content-center invoice-template"
      // ref={printContent}
      >
        <div className=" print-container" style={{width:"21cm",height:"29.7cm",fontSize:"12px",fontWeight:"500",lineHeight:"14px",fontFamily:"monospace"}}>
          <div className="card print-content p-3 pb-1" id="demo">
            <div className="row">
                  <p className="text-center fs-20 mt-2 mb-2" style={{fontWeight: "bolder"}}>{billDetail.tranport}</p>
                  <p className="text-center"><span style={{borderBottom: "1px dashed #999",textDecoration: "none"  }}>GOODS FORWARDING NOTE</span></p>
                  <p>CARRIER'S RISK</p>
                  <p>
                    Sir, Please book the consignments detailed below for transport  through your Loory, 
                    <b>From: Erode To: Mysore </b>
                  </p>
            </div>
            <div className="row mt-2" style={{borderBottom: "1px solid #0000005e"}}>
                  <div className="col-sm-4">
                      Name & Address of the Sender
                  </div>
                  <div className="col-sm-8">
                        <p>: VARI FABS</p>
                        <p>: S.F.NO:62/4 GANGAPURAM VILLAGE,</p>
                        <p>: CHITHODE POST,Erode - 638102</p>
                        <p>: GST.NO:33AAIFV4682P1ZF Phone:8012522017,</p>
                  </div>
            </div>
            <div className="row mt-2" style={{borderBottom: "1px solid #0000005e"}}>
                  <div className="col-sm-4">
                      Name & Address of the Consignee
                  </div>
                  <div className="col-sm-8">
                        <p>: {customerDetail.name}</p>
                        <p className="d-flex">: <p style={{marginLeft: "7px"}}>{billDetail.dispatch_through}</p></p>
                        <p>: GST.NO:{customerDetail.gstin}</p>
                  </div>
            </div>
            
            <div className="row mt-2" style={{borderBottom: "1px solid #0000005e"}}>
                  <div className="col-sm-4">
                      Bale No.<br>
                      </br>
                      Number & Description of the  Articles
                  </div>
                  <div className="col-sm-8">
                        <p>: {billDetail.dc_nos}</p>
                        <p>:</p>
                        <p>: One Bale(s) Containing Ready Made Goods</p>
                  </div>
            </div>
            
            <div className="row mt-2" style={{borderBottom: "1px solid #0000005e"}}>
                  <div className="col-sm-4">
                      Value in Figures<br>
                      </br>
                      Value in Words
                  </div>
                  <div className="col-sm-8">
                        <p>: {billDetail.net_amount}</p>
                        <p style={{fontSize: "10px",fontWeight: "500",textTransform: "capitalize"}}>:{NumberToWords(billDetail.net_amount)} Rupees Only</p>
                  </div>
            </div>
            <div className="row mt-2" style={{borderBottom: "1px solid #0000005e"}}>
                  <div className="col-sm-4">
                      Freight
                  </div>
                  <div className="col-sm-8">
                        <p>:    PASS      OR       WITH PASS</p>
                  </div>
            </div>

            <div className="row mt-2">
                  <div className="col-sm-6 row">
                      <div className="col-sm-4">
                          Station 
                      </div>
                      <div className="col-sm-8">
                          : ERODE - 638012 
                      </div>
                      <div className="col-sm-4">
                          Date 
                      </div>
                      <div className="col-sm-8">
                          : {moment(billDetail.date).format("DD-MM-YYYY")}
                      </div>
                  </div>
                  <div className="col-sm-6">
                    <br></br>
                        <p className="text-center"> Signature of the sender</p>
                  </div>
            </div>
            
            

          </div>
        </div>
      </div>
    </>
  );
};

export default ForwardInvoice;
