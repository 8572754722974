import ReactDOM from 'react-dom'

const Loader = ({ className }) => {
    return ReactDOM.createPortal(
        <div className={`loader-container ${className ? className : ""}`}>
            <img src="assets/Spin-1s-200px.svg" alt="Loader" className="loader" />
        </div>,
        document.getElementById('loader')
    )
}



export default Loader;