import React from "react";
import sidemenu from "../sidemenu";
import SidebarItem from "./SidebarItem";
import "../App.css";
import "../side.css";
import { useLocation, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { securityCheck } from "./Utils";

const Sidebar = () => {
  const location = useLocation();
  const history = useHistory();

  const [securityCodeList, setSecurityCodeList] = useState([]);
  const [pathName, setPathName] = useState("");
  const [collapse, setCollapse] = useState("");

  useEffect(() => {
    sidemenu.map((parent) => {
      parent.children.map((mainMenu) => {
        if (mainMenu.path === location.pathname) {
          setPathName(mainMenu.title);
          setCollapse("");
          return true;
        }

        mainMenu.children?.map((subMenu) => {
          if (subMenu.path == location.pathname) {
            setPathName(subMenu.title);
            setCollapse(mainMenu.title);
            return true;
          }

          subMenu.children?.map((subMenuchild) => {
            if (subMenuchild.path == location.pathname) {
              setPathName(subMenuchild.title);
              setCollapse(mainMenu.title);
            }
          });
        });
      });
    });

    let codeList = [];
    sidemenu.map((parent) => {
      let codes = [];
      parent.children.map((mainMenu) => {
        mainMenu.children?.map((subMenu) => {
          subMenu.children?.map((subMenuchild) => {
            if (findSecurityCode(subMenuchild.securityCode) > 0) {
              codes.push(subMenuchild.securityCode);
            }
          });
          codeList[subMenu.title] = codes;
          if (findSecurityCode(subMenu.securityCode) > 0) {
            codes.push(subMenu.securityCode);
          }
        });
        codeList[mainMenu.title] = codes;
        if (findSecurityCode(mainMenu.securityCode) > 0) {
          codes.push(mainMenu.securityCode);
        }
      });
      codeList[parent.title] = codes;
    });
    setSecurityCodeList(codeList);
  }, [location.pathname]);

  let user = JSON.parse(localStorage.getItem("userInfo"));

  let rightArray = [];
  if (user?.user_right) {
    rightArray = user?.user_right.split(",");
  }
  rightArray.push("dashboard");

  const findSecurityCode = (code) => {
    return rightArray.length > 0
      ? rightArray.filter((value) => value == code).length
      : 0;
  };

  const RedirectionHandler = (path) => {
    history.push(path);
    if (document.body.classList.contains("vertical-sidebar-enable")) {
      document.body.classList.remove("vertical-sidebar-enable");
    }
  };

  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        <a href="/" className="logo logo-light">
          <span className="logo-sm">
            <img src="assets/icons.png" alt="" height="40" />
          </span>
          <span className="logo-lg">
            <img src="assets/logo.e7af2590.png" alt="" height="45" />
          </span>
        </a>
        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      <div id="scrollbar" className="scroller">
        <div className="container-fluid">
          <div id="two-column-menu"></div>
          <ul className="navbar-nav" id="navbar-nav">
            {sidemenu.map((menu, indx) => {
              if (
                securityCodeList[menu.title] &&
                securityCodeList[menu.title].length > 0
              ) {
                return (
                  <div key={indx}>
                    <li className="menu-title">
                      <span data-key="t-menu">{menu.name}</span>
                    </li>
                    {menu.children.map((item) => {
                      if (
                        item.children &&
                        securityCodeList[item.title] &&
                        securityCodeList[item.title].length > 0
                      ) {
                        return (
                          <li className="nav-item" key={item.id}>
                            <a
                              className={`nav-link menu-link ${
                                pathName.toLowerCase() ==
                                item.title.toLowerCase()
                                  ? "active"
                                  : ""
                              }`}
                              href={`#${item.title}`}
                              data-bs-toggle="collapse"
                              role="button"
                              collapse={collapse}
                              aria-expanded={
                                collapse.toLowerCase() ==
                                item.title.toLowerCase()
                                  ? true
                                  : false
                              }
                              aria-controls={item.title}
                            >
                              <i className={item.icon}></i>{" "}
                              <span data-key="t-apps">{item.name}</span>
                            </a>
                            <div
                              className={`${
                                collapse.toLowerCase() ==
                                item.title.toLowerCase()
                                  ? "show"
                                  : ""
                              } collapse menu-dropdown`}
                              id={item.title}
                            >
                              {item.children.map((submenu, index) => {
                                if (
                                  securityCheck(submenu, rightArray) &&
                                  securityCodeList[submenu.title] &&
                                  securityCodeList[submenu.title].length > 0
                                ) {
                                  return (
                                    <SidebarItem
                                      key={index}
                                      submenu={submenu}
                                      pathName={pathName}
                                      rightArray={rightArray}
                                    />
                                  );
                                }
                              })}
                            </div>
                          </li>
                        );
                      } else {
                        return (
                          <li className="nav-item" key={item.id}>
                            <span
                              className={`nav-link menu-link ${
                                pathName.toLowerCase() ==
                                item.title.toLowerCase()
                                  ? "active"
                                  : ""
                              }`}
                              id="dashboard-item"
                              onClick={RedirectionHandler.bind(this, item.path)}
                            >
                              <i className={item.icon}></i>{" "}
                              <span data-key="t-dashboards">{item.name}</span>
                            </span>
                          </li>
                        );
                      }
                    })}
                  </div>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
