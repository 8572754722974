import React, { Suspense } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer, Bounce } from "react-toastify";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import routes from "./routes";

import BaseLayout from "./Layout/BaseLayout";
import Login from "./Pages/Login";
import Loader from "./Pages/Loader";
import SalesLetterPadPrint from "./PrintTemplate/SalesLetterPadInvoice";
import SalesA4Print from "./PrintTemplate/SalesA4Invoice";
import DcInvoice from "./PrintTemplate/DcInvoice";
import ForwardInvoice from "./PrintTemplate/ForwardInvoice";

function App() {
  return (
    <React.StrictMode>
      <ToastContainer
        autoClose={2000}
        theme="colored"
        className="toaster-container"
        position="top-right"
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
      <HashRouter>
        <Suspense fallback={<Loader />}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            ></Route>
            <Route
              exact
              path="/sales/LetterPad/:id"
              name="Print Page"
              render={(props) => <SalesLetterPadPrint {...props} />}
            ></Route>
            <Route
              exact
              path="/sales/A4/:id"
              name="Print Page"
              render={(props) => <SalesA4Print {...props} />}
            ></Route>
            <Route
              exact
              path="/Dc/Invoice/:id"
              name="Print Page"
              render={(props) => <DcInvoice {...props} />}
            ></Route>
            <Route
              exact
              path="/forwardInvoice/:id"
              name="Print Page"
              render={(props) => <ForwardInvoice {...props} />}
            ></Route>
            {routes.map((route, index) => {
              return route.component ? (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => (
                    <BaseLayout>
                      <route.component {...props} />
                    </BaseLayout>
                  )}
                />
              ) : null;
            })}
            <Route path="*" key="others">
              <Redirect to="/login" />
            </Route>
          </Switch>
        </Suspense>
      </HashRouter>
    </React.StrictMode>
  );
}

export default App;
