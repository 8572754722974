export const SET_ENTRIES = 'SET_ENTRIES';
export const ADD_LEDGER_ID = 'ADD_LEDGER_ID';
export const UPDATE_FORM_ENTRIES = 'UPDATE_INPUT_ENTRIES';
export const UPDATE_TABLE_ENTIRES = 'UPDATE_TABLE_ENTRIES';
export const REMOVE_ENTRIES = 'REMOVE_ENTRIES';

export const setEntries = data => {
    return { type: SET_ENTRIES, payload: data };
};
export const updateLedgerId = LedgerId => {
    return { type: ADD_LEDGER_ID, payload: LedgerId };
};
export const updateFormEntries = formData => {
    return { type: UPDATE_FORM_ENTRIES, payload: formData };
};
export const updateTableEntries = tableData => {
    return { type: UPDATE_TABLE_ENTIRES, payload: tableData };
};
export const removeEntries = () => {
    return { type: REMOVE_ENTRIES };
};