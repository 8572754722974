import { SET_ENTRIES, ADD_LEDGER_ID, UPDATE_FORM_ENTRIES, UPDATE_TABLE_ENTIRES, REMOVE_ENTRIES } from "../Action/Entries";

const initialState = {
    input: {},
    tableData: [],
    count: 0,
    previousCount: 0,
};

const entriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ENTRIES:
            return {
                ...state,
                input: {
                    // ...state.input,
                    ...action.payload.input
                },
                tableData: action.payload?.tableData ? action.payload.tableData : [],
            };
        case ADD_LEDGER_ID:
            return {
                ...state,
                input: {
                    ...state.input,
                    ledger_id: action.payload,
                }
            };
        case UPDATE_FORM_ENTRIES:
            return {
                ...state,
                input: {
                    ...state.input,
                    ...action.payload
                },
            };
        case UPDATE_TABLE_ENTIRES:
            let count = action.payload.length;
            let previousCount = state.tableData.length;

            return {
                ...state,
                tableData: action.payload ? action.payload : [],
                count: count,
                previousCount: previousCount
            };
        case REMOVE_ENTRIES:
            return {
                ...state,
                ...initialState,
            };
        default:
            return state;
    }
};

export default entriesReducer;
