import { useState, useEffect, useRef, useMemo } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { NumberToWords } from "../components/Utils";
import axios from "../axios/index";

import Table from "../components/Table/Table";
import "./InvoiceTemplate.scss";
import "./Print.scss";

const SalesA4Invoice = () => {

    const { id } = useParams();

    const printContent = useRef(null);
    const [customerDetail, setCustomerDetail] = useState([]);
    const [itemsDetail, setItemsDetail] = useState([]);
    const [visibleDetail, setVisibleDetail] = useState([]);
    const [emptyRow, setEmptyRow] = useState([{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    },{
      priority_id: "0"      
    }]);

    const getSubGroupName = async () => {
      await axios
        .get(`sales/print/${id}`)
        .then((response) => response.data)
        .then((data) => {
          
          setCustomerDetail(data.detail);        
          setItemsDetail(data.items);        
          setVisibleDetail(data.data);  
          window.print();
          window.close();
            let row=8;
            let rowaval=data.items.length();
            if(rowaval>8)
            {
              row=0;
            }
            else{
              row=8-rowaval;
            }
            let emprow=[];
            
            for(let i=0;i<=row;i++){
              emprow.push({
                priority_id: i,
              });      
              
            }            
            setEmptyRow([...emprow]);

          
        });
    };


  
  useEffect(() => {
    getSubGroupName();    
  }, []);
  
  
  
  
  return (
    <>
      <div
        className="row justify-content-center invoice-template"
      // ref={printContent}
      >
        <div className=" print-container" style={{width:"21cm",height:"29.7cm",fontSize:"12px",fontWeight:"600",lineHeight:"14px",fontFamily:"sans-serif"}}>
          <div className="card print-content" id="demo">
            {/* Bill-Header */}
            <div className="card-header py-3 pt-3 pb-1" style={{width:"100%",height:"3.5cm"}}>
              <div className="d-sm-flex">
                <div className="flex-grow-1" style={{ textAlign: "center" }}>
                  <h3 style={{fontWeight:"800",color:"black",color:"orange"}}>Sri Vinayaga Thunai Transport</h3>
                  <div className="mt-sm-2">
                    <p className=" text-uppercase ">
                    5/A/11, 1st Floor, Ulaipallar Street, Vellakinar,
                    Coimbatore - 641029.
                    </p>
                    <p className=" mb-0 " id="zip-code">
                    <span>Contact:</span> 9698119900, 9578879900
                    </p>
                    <p className=" mb-0 " id="zip-code">
                    <span>Website:</span> www.svttransport.in
                    </p>
                    <p className=" text-uppercase">
                        GST.NO : 33CDOPG2828K1ZD
                      </p>
                  </div>                  
                </div>
              </div>
            </div>

            {/* Bill-Customer-Detail */}
            <div className="card-body p-0 border-top border-bottom" style={{width:"100%",height:"3cm"}}>
              <div className="row g-0" style={{height:"100%"}}>
                <div className="col-sm-6 p-2 border-right">
                  <p className=" text-uppercase  mb-2 fs-11">
                    To,
                  </p>
                  <div className="ps-3">
                    <p className=" fs-11" id="billing-name">
                      {customerDetail.name}
                    </p>
                    <p className=" fs-11" id="billing-address-line-1">
                      {customerDetail.address}
                    </p>
                    
                    <p className=" fs-11">
                      <span>Phone No: </span>
                      <span id="billing-phone-no">
                        {customerDetail.mobile}
                      </span>
                    </p>
                    <p className=" fs-11 mb-0">
                      <span>GSTIN: </span>
                      <span id="billing-tax-no">{customerDetail.gstin}</span>
                    </p>
                  </div>
                </div>
                <div className="col-sm-6" style={{height:"100%",borderLeft:"1px solid black"}}>
                <div className="p-2 d-flex align-items-center ">
                    <div className="col-sm-3">
                    Invoice No
                    </div>
                    <div className="col-sm-6">
                    : {customerDetail.reference_no}
                    </div>
                  </div>
                  <div className="p-2 d-flex align-items-center ">
                    <div className="col-sm-3">
                    Date
                    </div>
                    <div className="col-sm-6">
                    : {moment(customerDetail.date).format("DD-MM-YYYY")}
                    </div>
                  </div>
                  {customerDetail.po_no && (
                  <div className="p-2 d-flex align-items-center ">
                    <div className="col-sm-3">
                    PO No
                    </div>
                    <div className="col-sm-6">
                    : {customerDetail.po_no}
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
           

            {/* Bill-Item */}
            <div className="card-body p-0 border-bottom" style={{width:"100%",height:"11cm"}}>
              
            


<table style={{width:"100%"}}>
              <thead style={{width:"100%"}}>
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>S No</th>
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>LR No</th>
                    
                    {visibleDetail.hsn>0 && (
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>HSN</th>
                    )}
                    
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>Invoice</th>
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>Date</th>
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>Freight Rs</th>
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>Freight KG</th>
                    
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>Vechicle No</th>          
                    
                    {visibleDetail.amc>0 && (          
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>AMC</th>
                    )}
                    {visibleDetail.others>0 && (
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>Others</th>
                    )}
                    {visibleDetail.lr_charges>0 && (
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>LR Charges</th>
                    )}
                    <th style={{fontSize:"11px",borderBottom:"1.5px solid black",height:"1cm",textAlign:"center",alignItems:"center"}}>Total</th>                    
                    
              </thead>
              <tbody>
              
            
              {itemsDetail.map((item,index) =>{
                // console.log(index)
                if(item.lr_no!=""){
                 return (
                    <>

                <tr>

                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"center",alignItems:"center"}}>
                      {index+1}
                    </td>
                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"center",alignItems:"center"}}>
                    {item.lr_no}
                    </td>

                    {visibleDetail.hsn>0 && (
                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"center",alignItems:"center"}}>
                    {item.hsn}
                    </td>
                    )}

                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"center",alignItems:"center"}}>
                    {item.inv_no}
                    </td>
                    
                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"center",alignItems:"center"}}>
                    {item.date}
                    </td>
                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"center",alignItems:"center"}}>
                    {item.rate}
                    </td>

                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"center",alignItems:"center"}}>
                    {item.freight_kg}
                    </td>

                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"center",alignItems:"center"}}>
                    {item.vechicle}
                    </td>                       
                    
                    {visibleDetail.amc>0 && (
                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"center",alignItems:"center"}}>
                    {parseFloat(item.amc).toFixed(0)}
                    </td>
                    )}

                    {visibleDetail.others>0 && (
                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"center",alignItems:"center"}}>
                    {parseFloat(item.others).toFixed(0)}
                    </td>
                    )}

                    {visibleDetail.lr_charges>0 && (
                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"center",alignItems:"center"}}>
                    
                    {parseFloat(item.lr_charges).toFixed(0)}
                    </td>
                    )}

                    <td style={{fontSize:"12px",fontWeight:"500",lineHeight:"25px",textAlign:"right",alignItems:"center"}}>
                    {/* {item.total} */}
                    {parseFloat((parseFloat((item.rate||0)))+parseFloat(item.amc||0)+parseFloat(item.others||0)+parseFloat(item.lr_charges||0)).toFixed(0)}
                    </td>

                </tr>
              
                </>
               );
                    }
              })
              
              }
             

              </tbody>
            </table>
              


              {emptyRow.map((row,index) =>{
                 return (
                    <>
                    
              <div className="row g-0 m-0 align-items-center" style={{minHeight:"0.8cm"}}>
                    <div style={{width:"5%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>

                    </div>
                    <div style={{width:"10%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                      
                    </div>
                    
                    <div style={{width:"10%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>

                    </div>
                    <div style={{width:"10%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>

                    </div>

                    <div style={{width:"10%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                          
                    </div>

                    <div style={{width:"10%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                          
                    </div>                       
                    
                    <div style={{width:"10%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                          
                    </div>

                    <div style={{width:"10%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                          
                    </div>

                    <div style={{width:"10%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                          
                    </div>
                    <div style={{width:"15%",fontSize:"11px",minHeight:"1cm",display:"grid",textAlign:"center",alignItems:"center"}}>
                          
                    </div>

              </div>

              </>
                );
              })
              }



            </div>
            {/* Bill-Amount */}
            <div className="card-body p-0 border-bottom" style={{width:"100%",minHeight:"3cm"}}>
              <div className="row g-0">
                <div className="col-sm-6">
                  <div className="p-2">
                    <p className="fs-12">
                      <span style={{fontWeight:"500",color:"black"}}>
                      Amount In Words :
                        <span className=" ms-1 text-transform-capitalize">
                          {NumberToWords(customerDetail.net_amount)} Rupees.
                        </span>
                      </span>
                    </p>
                  </div>                  
                </div>
                <div className="col-sm-6 border-left">
                  <div className="row g-0 h-100">
                    <div className="col-sm-8 pt-2 border-right d-flex d-print-flex flex-print-column flex-column">
                      
                      <p className="fs-12 mt-1 ps-2">
                        Sub Total
                      </p>
                      <p className="fs-12 mt-1 ps-2">CGST : </p>
                      <p className="fs-12 mt-1 ps-2">SGST : </p>
                      <p className="fs-12 mt-auto mb-2 border-bottom ps-2 pb-2">
                        Round Off 
                      </p>
                      <p className="fs-12 mb-2 ps-2">NET TOTAL : </p>
                    </div>
                    <div
                      className="col-sm-4 pt-2 d-flex d-print-flex flex-print-column flex-column"
                      style={{ textAlign: "end" }}
                    >
                      <p className="fs-12 mt-1 pe-2">
                        {parseFloat(customerDetail.gross_amount).toFixed(0)}                        
                      </p>
                      
                      <p className="fs-12 mt-1 pe-2">
                      {parseFloat(customerDetail.tax_amount/2).toFixed(0)}
                      </p>
                      <p className="fs-12 mt-1 pe-2">
                        {parseFloat(customerDetail.tax_amount/2).toFixed(0)}
                      </p>
                      <p className="fs-12 mt-auto mb-2 border-bottom pe-2 pb-2">
                      {parseFloat(customerDetail.net_amount-parseFloat(parseFloat(customerDetail.gross_amount)+parseFloat(customerDetail.tax_amount)).toFixed(0)).toFixed(0)}
                      </p>
                      <p className="fs-12 mb-2 pe-2">
                        {parseFloat(customerDetail.net_amount).toFixed(0)}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Bill-Sign */}
            <div className="card-body  p-0" style={{width:"100%",height:"3cm"}}>
              <div className="row g-0">
                <div className="col-sm-6 ps-2">
                  <div className="p-2">
                    <p className="fs-12 mt-1">Document Enclosed: </p>
                    <p className="fs-11  ms-1 mb-1 text-center">
                        {itemsDetail.length>1 && (

                        <>
                        {itemsDetail.length }- LR Copies
                        </>
                        )}
                        {itemsDetail.length==1 && (

                        <>
                        "1 - LR Copy"
                        </>
                        )}
                    </p>
                    <p className="fs-11  ms-1 mb-1">
                    1. All payments to be made by crossed Cheque/DD in favour of the SRI VINAYAGA THUNAI TRANSPORTS
                    </p>
                    <p className="fs-11  ms-1 mb-1">
                    2. Interest will be charged at 18% per annum on outstanding after one week from the date of this bill until
realisation
                    </p>
           
                  </div>
                </div>
                <div className="col-sm-6 pe-2" style={{ textAlign: "end" }}>
                <p className="fs-12 p-2">For <span style={{color:"orange",fontSize:"14px"}}>Sri Vinayaga Thunai Transport</span></p>
                  <p className=" mt-5 mb-0">Authorized Signature</p>
                </div>
              </div>
            </div>
            {/* <div className="hstack gap-2 justify-content-end d-print-none mb-2 m-auto">
            <a className="btn btn-success" onClick={() => window.print()}>
              <i className="ri-printer-line align-bottom me-1"></i> Print
            </a>
            <a className="btn btn-primary">
              <i className="ri-download-2-line align-bottom me-1"></i> Download
            </a>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SalesA4Invoice;
