const sidemenu = [
  {
    id: "dashboard",
    title: "Dashboard",
    name: "Dashboard",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        name: "Dashboard",
        icon: "ri-dashboard-2-line",
        path: "/dashboard",
        securityCode: "dashboard",
      },
      {
        id: "Supplier",
        title: "Supplier",
        name: "Supplier",
        icon: "ri-file-user-line",
        path: "/ledger/ledgerlist",
        securityCode: "ledger_list",
      },
      {
        id: "sales",
        title: "Sales",
        name: "Sales",
        icon: "ri-computer-line",
        path: "/sales/list",
        securityCode: "sales_list",
      },
      // {
      //   id: "voucher",
      //   title: "Voucher",
      //   name: "Voucher",
      //   icon: " ri-qr-scan-line",
      //   path: "/voucher/list",
      //   securityCode: "voucher_list",
      // },
      // {
      //   id: "payable",
      //   title: "Payable",
      //   name: "Payable",
      //   icon: "ri-alipay-line",
      //   path: "/reports/voucher/payable",
      //   securityCode: "voucher_payable",
      // },
      {
        id: "receivable",
        title: "Receivable",
        name: "Receivable",
        icon: " ri-hand-coin-line",
        path: "/reports/voucher/receivable",
        securityCode: "voucher_receivable",
      },
      {
        id: "statement",
        title: "Statement",
        name: "Statement",
        icon: " ri-newspaper-line",
        path: "/reports/voucher/statement",
        securityCode: "voucher_statement",
      },      
      {
        id: "report",
        title: "Report",
        name: "Report",
        icon: "ri-folder-transfer-fill",
        path: "/others",
        securityCode: "others",
        children: [
          {
            id: "salesreport",
            title: "Sales Report",
            name: "Sales Report",
            icon: " ri-git-repository-fill",
            path: "/reports/voucher/sales",
            securityCode: "voucher_statement",
          },
          {
            id: "partyreport",
            title: "Party Report",
            name: "Party Report",
            icon: "ri-folder-user-fill",
            path: "/reports/voucher/party",
            securityCode: "voucher_statement",
          },          
        ],
      },
    ],
  }
];

export default sidemenu;
