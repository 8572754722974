import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import { useFormik } from "formik";
import axios from "../axios/index";
import { useDispatch, useSelector } from "react-redux";
import { authLogin } from "../STORE/Action/Auth";
import InputContainer from "../components/InputContainer/InputContainer";
import ButtonLoader from "../components/Buttons/ButtonLoader";

const validate = (values) => {
  let errors = {};
  if (!values.user_email) {
    errors.user_email = "Field is Required";
  }

  if (!values.user_password) {
    errors.user_password = "Field is Required";
  }

  return errors;
};

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [saveButtonElement, setButtonLoading] = ButtonLoader(
    "Sign In",
    "Loading..."
  );
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.user_id) {
      history.replace("./");
    } else {
      history.replace("./login");
    }
  }, [auth.user_id]);

  const formik = useFormik({
    initialValues: {
      user_email: "",
      user_password: "",
    },
    validate,

    onSubmit: (values) => {
      setButtonLoading(true);
      axios
        .post("Auth/signin", {
          user_email: values.user_email,
          user_password: values.user_password,
        })
        .then((response) => {
          if (response.data.status === 1) {
            localStorage.setItem("userInfo", JSON.stringify(response.data));
            dispatch(authLogin(response.data));
            history.push("/");
          } else {
            swal("Invalid Email or Password");
          }
          setButtonLoading(false);
        })
        .catch((response) => {
          swal("Error", response.data.error.message);
          setButtonLoading(false);
        });
    },
  });

  return (
    <div className="auth-page-wrapper pt-5">
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
        <div className="bg-overlay"></div>

        <div className="shape">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 1440 120"
          >
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
        </div>
      </div>

      <div className="auth-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div style={{ color: "white", fontSize: "28px" }}>
                <img src="assets/logo.e7af2590.png" alt="" height="50" />  
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Have A Nice Day !</h5>
                    <p className="text-muted">
                      Sign in to continue.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="mb-1">
                        <InputContainer
                          label="Username"
                          type="text"
                          id="username"
                          name="user_email"
                          placeholder="Enter username"
                          onChange={formik.handleChange}
                          value={formik.values.user_email}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.user_email &&
                          formik.touched.user_email && (
                            <span className="error-msg">
                              {formik.errors.user_email}
                            </span>
                          )}
                      </div>

                      <div className="mb-1">
                        <InputContainer
                          label="Password"
                          type="password"
                          id="password-input"
                          name="user_password"
                          placeholder="Enter password"
                          onChange={formik.handleChange}
                          value={formik.values.user_password}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.user_password &&
                          formik.touched.user_password && (
                            <span className="error-msg">
                              {formik.errors.user_password}
                            </span>
                          )}
                      </div>

                      <div className="mt-4">
                        <button
                          type="submit"
                          className="btn btn-success btn-load w-100"
                          ref={saveButtonElement}
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
