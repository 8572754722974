import { forwardRef } from "react";
import "./InputContainer.scss";
import Select from "react-select";
import axios from "../../axios/index";
import moment from "moment";
import { DatePicker } from "antd";
import { useEffect } from "react";
import { useState } from "react";

const InputContainer = forwardRef(
  (
    {
      name,
      value,
      placeholder,
      id,
      className,
      type,
      label,
      labelClassName,
      error,
      onChange,
      onKeyDown,
      options,
      optionsLabelKey,
      optionsValueKey,
      required,
      labelAlign,
      optionsUrl,
      optionsParam,
      disabled,
      readOnly,
      containerClassName,
      tableInline,
      onKeyUp,
      min,
      step,
      autoFocus,
      isMulti,
      style,
      BeforeOnKeyDown,
      isClearable,
      AfterOnKeyDown,
    },
    ref
  ) => {
    const [optionsFromUrl, setOptionsFromUrl] = useState([]);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedValue, setSelectedValue] = useState({});

    const findNextTabStop = (el) => {
      var universe = document.querySelectorAll(
        "input:not([disabled]), button, select, textarea, a[href]"
      );
      var list = Array.prototype.filter.call(universe, function (item) {
        return item.tabIndex >= "0";
      });
      var index = list.indexOf(el);
      return list[index + 1] || list[0];
    };

    const findPreviousTabStop = (el) => {
      var universe = document.querySelectorAll(
        "input:not([disabled]), button, select, textarea, a[href]"
      );
      var list = Array.prototype.filter.call(universe, function (item) {
        return item.tabIndex >= "0";
      });
      var index = list.indexOf(el);
      return list[index - 1] || list[0];
    };

    const findSelectNextTabStop = () => {
      let element = document.querySelector(`[aria-labelledby="${id}"]`);
      var universe = document.querySelectorAll(
        "input:not([disabled]), button, select, textarea, a[href]"
      );
      var list = Array.prototype.filter.call(universe, function (item) {
        return item.tabIndex >= "0";
      });
      var index = list.indexOf(element);
      return list[index + 1] || list[0];
    };

    const onChangeSelect = async (e) => {
      if (BeforeOnKeyDown) {
        await BeforeOnKeyDown(e);
      }
      await onChange(e);
      if (AfterOnKeyDown) {
        await AfterOnKeyDown(e);
      }
      let element = findSelectNextTabStop();
      element.focus();
    };

    const onChangeInput = (e) => {
      let event = e;
      let regex = /[^A-Za-z0-9,@.&/-\s]/gi;
      event.target.value = e.target.value.replace(regex, "");
      onChange(event);
    };

    const onDateChange = (date, dateString) => {
      let response = {};
      response.target = {
        name: name,
        value: dateString,
      };
      onChange(response);
      setShowDatePicker(false);
    };

    const handleEnter = async (event) => {
      if (BeforeOnKeyDown) {
        await BeforeOnKeyDown(event);
      }
      let selection = document.getSelection();
      if (
        (selection.type.toLowerCase() === "range" ||
          event.target.value.length == 0) &&
        event.key.toLowerCase() === "backspace"
      ) {
        let element = findPreviousTabStop(event.target);
        element.focus();
        if (
          element.type &&
          element.type !== "button" &&
          element.type !== "submit"
        ) {
          element.select();
        }
        event.preventDefault();
      }

      if (
        (type !== "select" ||
          (type == "select" && event.target.ariaExpanded == "false")) &&
        event.key.toLowerCase() === "enter"
      ) {
        let element = findNextTabStop(event.target);
        element.focus();
        if (
          element.type &&
          element.type !== "button" &&
          element.type !== "submit"
        ) {
          element.select();
        }
        event.preventDefault();
      }

      if (
        type == "date" &&
        (event.key.toLowerCase() === "enter" ||
          (event.target.value.length == 0 &&
            event.key.toLowerCase() === "backspace"))
      ) {
        setShowDatePicker(false);
      }

      if (
        type == "date" &&
        !(event.key.toLowerCase() === "enter") &&
        !(event.key.toLowerCase() === "backspace")
      ) {
        setShowDatePicker(true);
      }

      if (type !== "select" && type !== "button" && onKeyDown) {
        onKeyDown(event);
      }
    };

    useEffect(() => {
      if (optionsUrl) {
        const getOptions = async () => {
          await axios.get(optionsUrl).then((res) => {
            setOptionsFromUrl(
              res.data[optionsParam] ? res.data[optionsParam] : []
            );
          });
        };

        getOptions();
      }
    }, [optionsUrl, optionsParam]);

    const selectValue = () => {
      let selectOptions = optionsFromUrl.length > 0 ? optionsFromUrl : options;
      if (value && isMulti) {
        return selectOptions?.filter((obj) => {
          if (value.includes(obj[optionsValueKey])) {
            return obj;
          }
        });
      }

      if (value) {
        return selectOptions?.find(
          (option) => option[optionsValueKey] == value
        );
      }

      return null;
    };

    useEffect(() => {
      if (type == "select") {
        setSelectedValue(selectValue());
      }
    }, [value, type, optionsFromUrl, options]);

    const SelectLabelRender = (option, label) => {
      let labelArray = label.split(",");
      let optionLabel = "";
      labelArray.map((labl, index) => {
        optionLabel = `${optionLabel} ${index == 0 ? `${option[labl]}` : ` - ${option[labl]}`}`
      });
      return optionLabel;
    }

    return (
      <div
        className={`input-container ${
          labelAlign == "left" ? "d-flex flex-row m-5-0" : ""
        } ${containerClassName ? containerClassName : ""}`}
      >
        {label && (
          <div
            className={`${!tableInline && "mt-1"} ${
              labelAlign == "left" ? "col-4 col-sm-4 col-md-4" : "col-md-12"
            }`}
          >
            <label htmlFor={id} className={`form-label ${labelClassName}`}>
              {label}
              {required && <span className="required">*</span>}
            </label>
          </div>
        )}
        <div
          className={`mt-1 ${
            labelAlign == "left" ? "col-8 col-sm-8 col-md-8" : "col-md-12"
          }`}
        >
          {(type === "text" ||
            type === "number" ||
            type === "email" ||
            type === "password") && (
            <input
              type={type}
              className={`form-control ${className ? className : ""} ${
                error && error.length > 0 ? "error-border" : ""
              } ${tableInline ? "no-border" : ""}`}
              id={id}
              name={name}
              value={value ? value : ""}
              onChange={onChange ? onChangeInput : () => {}}
              ref={ref}
              onKeyDown={handleEnter}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              min={min}
              step={step}
              autoFocus={autoFocus}
              style={style}
            />
          )}
          {type == "date" && (
            <DatePicker
              className={`form-control ${tableInline ? "no-border" : ""}`}
              onChange={onDateChange}
              id={id}
              name={name}
              onKeyDown={handleEnter}
              open={showDatePicker}
              ref={ref}
              placeholder={placeholder}
              dropdownClassName="picker-dropdown-classname"
              format="DD-MM-YYYY"
              value={
                !value || moment(value, "DD-MM-YYYY") === "Invalid date"
                  ? undefined
                  : moment(value, "DD-MM-YYYY")
              }
              disabled={disabled}
              readOnly={readOnly}
              allowClear={false}
            />
          )}
          {type == "select" && (
            <Select
              isMulti={isMulti}
              className={`select-input ${className} ${
                error && error.length > 0 ? "error-border" : null
              } ${tableInline ? "no-border" : ""}`}
              value={selectedValue}
              onChange={(e) => onChangeSelect(e)}
              placeholder={placeholder}
              aria-labelledby={id}
              options={optionsUrl ? optionsFromUrl : options}
              ref={ref}
              onKeyDown={handleEnter}
              form={name}
              closeMenuOnSelect={true}
              getOptionLabel={(option) => SelectLabelRender(option, optionsLabelKey)}
              getOptionValue={(option) => option[optionsValueKey]}
              disabled={disabled}
              readOnly={readOnly}
              onKeyUp={onKeyUp}
              isClearable={isClearable}
              styles={customStyles}
              menuPortalTarget={document.body}
            />
          )}
          {type === "textarea" && (
            <textarea
              type={type}
              className={`form-control ${className ? className : ""} ${
                error && error.length > 0 ? "error-border" : ""
              } ${tableInline ? "no-border" : ""}`}
              id={id}
              name={name}
              value={value ? value : ""}
              onChange={onChange ? onChangeInput : () => {}}
              ref={ref}
              onKeyDown={handleEnter}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              min={min}
              step={step}
              style={style}
              autoFocus={autoFocus}
            />
          )}
          <span className="error-statement">{error}</span>
        </div>
      </div>
    );
  }
);

export default InputContainer;

const customStyles = {
  // menuList: (provided, state) => ({
  //   ...provided,
  //   zIndex: 2000
  // }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};
