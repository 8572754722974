import React from "react";
import { Switch, Route } from "react-router-dom";
import routes from "../routes";
import AppBreadcrumb from "./AppBreadcrumb";

const Content = ({ children }) => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <Switch>
            {routes.map((crumb, key) => (
              <Route
                path={crumb.path}
                exact={crumb.exact}
                name={crumb.name}
                main={crumb.main}
                sub={crumb.sub}
                key={key}
                render={(props) => {
                  const crumbs = routes.filter(({ path }) =>
                    props.match.path.includes(path)
                  );

                  return (
                    <div className="p-8">
                      <AppBreadcrumb crumbs={crumbs} />
                    </div>
                  );
                }}
              />
            ))}
          </Switch>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Content;
