import React from "react";

const Dashboard = React.lazy(() => import("./Views/Dashboard"));
const PriorityList = React.lazy(() => import("./Views/PriorityList"));
const PriorityListItems = React.lazy(() => import("./Views/PriorityListItems"));
const PriorityListItemParty = React.lazy(() => import("./Views/PriorityListItemParty"));
const PriorityTable = React.lazy(() => import("./Views/Priority/PriorityTable"));
const PendingOrder = React.lazy(() => import("./Views/Priority/PendingOrders"));
const ReadyOrder = React.lazy(() => import("./Views/Priority/ReadyOrders"));
const BillBalance = React.lazy(() => import("./Views/Priority/BillwiseBalance"));
const PriorityTableItems = React.lazy(() => import("./Views/Priority/PriorityTableItems"));
const FabricItems = React.lazy(() => import("./Views/Priority/FabricItem"));
const AccessoriesItems = React.lazy(() => import("./Views/Priority/AccessoriesItem"));
const ItemMaster = React.lazy(() =>
  import("./Views/ProductMaster/Product/ItemMaster")
);
const ColorMaster = React.lazy(() =>
  import("./Views/ProductMaster/ColorMaster")
);
const SizeMaster = React.lazy(() => import("./Views/ProductMaster/SizeMaster"));
const ProcessGroupMaster = React.lazy(() =>
  import("./Views/ProductMaster/ProcessGroupMaster")
);
const ProcessType = React.lazy(() =>
  import("./Views/ProductMaster/ProcessType")
);
const VehicleMaster = React.lazy(() => import("./Views/Others/VehicleMaster"));
const FuelMaster = React.lazy(() => import("./Views/Others/Fuel/FuelMaster"));
const FuelCreation = React.lazy(() =>
  import("./Views/Others/Fuel/FuelCreation")
);
const TransportMaster = React.lazy(() =>
  import("./Views/Others/TransportMaster")
);
const DepartmentMaster = React.lazy(() =>
  import("./Views/ProductMaster/DepartmentMaster")
);
const ItemSubGroupMaster = React.lazy(() =>
  import("./Views/ProductMaster/ItemSubGroupMaster")
);
const CityMaster = React.lazy(() =>
  import("./Views/Ledger/CityMaster")
);
const AreaCreation = React.lazy(() => import("./Views/Ledger/AreaCreation"));
const AccountGroup = React.lazy(() => import("./Views/Ledger/AccountGroup"));
const PartyGroup = React.lazy(() => import("./Views/Ledger/PartyGroup"));
const LedgerList = React.lazy(() => import("./Views/Ledger/LedgerList"));
const LedgerCreation = React.lazy(() =>
  import("./Views/Ledger/LedgerCreation")
);
const ItemCreation = React.lazy(() =>
  import("./Views/ProductMaster/Product/ItemCreation")
);
const SalesEntry = React.lazy(() => import("./Views/Entry/Sales/SalesEntry"));
const SalesList = React.lazy(() => import("./Views/Entry/Sales/SalesList"));
const UserList = React.lazy(() => import("./Views/Others/Users/UserList"));
const UserRights = React.lazy(() => import("./Views/Others/Users/UserRights"));
const ItemGroupList = React.lazy(() =>
  import("./Views/ProductMaster/ItemGroupMaster/ItemGroupList")
);
const ItemGroupAdd = React.lazy(() =>
  import("./Views/ProductMaster/ItemGroupMaster/ItemGroupAdd")
);
const PurchaseList = React.lazy(() =>
  import("./Views/Entry/Purchase/PurchaseList")
);
const PurchaseEntry = React.lazy(() =>
  import("./Views/Entry/Purchase/PurchaseEntry")
);
const SalesOrderList = React.lazy(() =>
  import("./Views/Entry/SalesOrder/SalesOrderList")
);
const SalesOrderAdd = React.lazy(() =>
  import("./Views/Entry/SalesOrder/SalesOrderAdd")
);
const PurchaseOrderList = React.lazy(() =>
  import("./Views/Entry/PurchaseOrder/PurchaseOrderList")
);
const PurchaseOrderAdd = React.lazy(() =>
  import("./Views/Entry/PurchaseOrder/PurchaseOrderAdd")
);
const ProcessSubGroup = React.lazy(() =>
  import("./Views/ProductMaster/ProcessSubGroup")
);
const ProcessSubGroupAccessories = React.lazy(() =>
  import("./Views/ProductMaster/ProcessItemAccessories")
);
const StockAdjustmentList = React.lazy(() =>
  import("./Views/Entry/StockAdjustment/StockAdjustmentList")
);
const StockAdjustmentAdd = React.lazy(() =>
  import("./Views/Entry/StockAdjustment/StockAdjustmentAdd")
);
const DCAdd = React.lazy(() => import("./Views/Entry/DC/DCAdd"));
const DCList = React.lazy(() => import("./Views/Entry/DC/DCList"));
const SalesOrderDetail = React.lazy(() => import("./Views/SalesOrderDetail"));
const VoucherPayable = React.lazy(() =>
  import("./Views/Report/Voucher/VoucherPayable")
);
const VoucherReceivable = React.lazy(() =>
  import("./Views/Report/Voucher/VoucherReceivable")
);
const VoucherStatement = React.lazy(() =>
  import("./Views/Report/Voucher/VoucherStatement")
);
const VoucherSalesReport = React.lazy(() =>
  import("./Views/Report/Voucher/SalesReport")
);
const VoucherPartyReport = React.lazy(() =>
  import("./Views/Report/Voucher/PartyReport")
);
const VoucherGroupwise = React.lazy(() =>
  import("./Views/Report/Voucher/VoucherGroupwise")
);
const VoucherList = React.lazy(() =>
  import("./Views/Entry/Voucher/VoucherList")
);
const VoucherAddUpdate = React.lazy(() =>
  import("./Views/Entry/Voucher/VoucherAddUpdate")
);

const PriceList = React.lazy(() =>
  import("./Views/Report/PriceList/PriceList")
);

const WeeklyPayReceive = React.lazy(() =>
  import("./Views/Report/WeeklyFinancial/WeeklyPayReceive")
);
const InvoiceTemplate = React.lazy(() =>
  import("./PrintTemplate/InvoiceTemplate")
);
const SaleLetterInvoiceTemplate = React.lazy(() =>
  import("./PrintTemplate/SalesLetterPadInvoice")
);

const JournalList = React.lazy(() =>
  import("./Views/Entry/Journal/JournalList")
);
const JournalAddUpdate = React.lazy(() =>
  import("./Views/Entry/Journal/JournalAddUpdate")
);

const EmployeeList = React.lazy(() =>
  import("./Views/Transaction/HR/EmployeeList")
);
const WorkSheetSection = React.lazy(() =>
  import("./Views/Transaction/HR/WorkSheetSection")
);
const WorkSheetList = React.lazy(() =>
  import("./Views/Transaction/HR/WorkSheetList")
);
const UnitList = React.lazy(() =>
  import("./Views/Transaction/HR/PayRoll/UnitList")
);
const WagesList = React.lazy(() =>
  import("./Views/Transaction/HR/PayRoll/WagesList")
);
const EmployeeAddUpdate = React.lazy(() =>
  import("./Views/Transaction/HR/EmployeeAddUpdate")
);
const ProductWiseSale = React.lazy(() =>
  import("./Views/Report/Sales/ProductWiseSale")
);
const TaxWiseSale = React.lazy(() =>
  import("./Views/Report/Sales/TaxWiseSale")
);
const DebitNoteSale = React.lazy(() =>
  import("./Views/Report/Sales/DebitNoteSale")
);
const ProductWisePur = React.lazy(() =>
  import("./Views/Report/Purchase/ProductWisePur")
);
const TaxWisePur = React.lazy(() =>
  import("./Views/Report/Purchase/TaxWisePur")
);
const ZeroStock = React.lazy(() => import("./Views/Report/Stock/ZeroStock"));
const DetailStock = React.lazy(() =>
  import("./Views/Report/Stock/DetailStock")
);
const BarcodeGenerator = React.lazy(() =>
  import("./Views/Barcode/BarcodeGenerator")
);
const InWardList = React.lazy(() => import("./Views/Entry/InWard/InWardList"));
const InWardAddUpdate = React.lazy(() =>
  import("./Views/Entry/InWard/InWardAddUpdate")
);

const OutWardList = React.lazy(() =>
  import("./Views/Entry/OutWard/OutWardList")
);
const OutWardAddUpdate = React.lazy(() =>
  import("./Views/Entry/OutWard/OutWardAddUpdate")
);

const FabricInWardList = React.lazy(() =>
  import("./Views/Entry/FabricInward/FabricInWardList")
);
const FabricInWardAddUpdate = React.lazy(() =>
  import("./Views/Entry/FabricInward/FabricInWardAddUpdate")
);
const AgentCreation = React.lazy(() => import("./Views/Ledger/AgentCreation"));
const AgentSaleComm = React.lazy(() =>
  import("./Views/Report/AgentSaleComm/AgentSaleComm")
);
const LrList = React.lazy(() => import("./Views/Entry/LR/LrList"));
const SectionMaster = React.lazy(() => import("./Views/Others/SectionMaster"));
const OtherBillList = React.lazy(() =>
  import("./Views/Entry/OtherBills/OtherBillList")
);
const OtherBillAddUpdate = React.lazy(() =>
  import("./Views/Entry/OtherBills/OtherBillAddUpdate")
);
const ProgramChartList = React.lazy(() =>
  import("./Views/Entry/ProgramChart/ProgramChartList")
);
const ProgramChartCreate = React.lazy(() =>
  import("./Views/Entry/ProgramChart/ProgramChartCreate")
);
const BalanceSheet = React.lazy(() =>
  import("./Views/Report/Outstanding/BalanceSheet")
);
const ProfitLoss = React.lazy(() =>
  import("./Views/Report/Outstanding/ProfitLoss")
);
const OrderStatus = React.lazy(() =>
  import("./Views/Report/Stock/OrderStatus")
);
const LedgerMonthlySummary = React.lazy(() =>
  import("./Views/Report/Outstanding/LedgerMonthlySummary")
);

const routes = [
  {
    path: "/",
    component: Dashboard,
    name: "Dashboard",
    exact: true,
    securityCode: "dashboard",
  },
  {
    path: "/PriorityList",
    component: PriorityTable,
    name: "Priority List",
    exact: true,
    securityCode: "dashboard",
  },
  {
    path: "/PendingOrder",
    component: PendingOrder,
    name: "Pending Order List",
    exact: true,
    securityCode: "dashboard",
  },
  {
    path: "/ReadyOrder",
    component: ReadyOrder,
    name: "Ready Orders List",
    exact: true,
    securityCode: "dashboard",
  },
  {
    path: "/PriorityListItems/:id/:day",
    component: PriorityListItems,
    name: "Priority Items",
    exact: true,
    securityCode: "dashboard",
  },
  {
    path: "/PriorityListItemParty/:id/:day",
    component: PriorityListItemParty,
    name: "Priority Items",
    exact: true,
    securityCode: "dashboard",
  },
  {
    path: "/PriorityTableItems/:id",
    component: PriorityTableItems,
    name: "Priority Items",
    exact: true,
    securityCode: "dashboard",
  },
  {
    path: "/BillBalance/:type",
    component: BillBalance,
    name: "Bill Balance",
    exact: true,
    securityCode: "dashboard",
  },
  {
    path: "/fabricorder",
    component: FabricItems,
    name: "Fabric Order",
    exact: true,
    securityCode: "dashboard",
  },
  {
    path: "/accessoriesOrder",
    component: AccessoriesItems,
    name: "Accessories Order",
    exact: true,
    securityCode: "dashboard",
  },
  {
    path: "/dashboard",
    component: Dashboard,
    name: "Dashboard",
    main: "Dashboard",
    sub: "Dashboards",
    exact: true,
    securityCode: "dashboard",
  },
  {
    path: "/dashboard/salesorder/:id",
    component: SalesOrderDetail,
    name: "Sales Order Detail",
    main: "Dashboard",
    sub: "Sales Order Detail",
    link: "/dashboard",
    securityCode: "sales_order_list",
  },
  {
    path: "/product/item",
    component: ItemMaster,
    name: "Item Master",
    main: "Item",
    sub: "List",
    securityCode: "itemMaster_list",
  },
  {
    path: "/product/creation",
    component: ItemCreation,
    name: "Item Master",
    main: "Item",
    sub: "Add",
    link: "/product/item",
    securityCode: "itemMaster_add",
  },
  {
    path: "/product/update/:id",
    component: ItemCreation,
    name: "Item Master",
    main: "Item",
    sub: "Add",
    link: "/product/item",
    securityCode: "itemMaster_edit",
  },
  {
    path: "/product/itemgrouplist",
    component: ItemGroupList,
    name: "Item Group Master",
    main: "Item Group",
    sub: "List",
    securityCode: "itemgroup_master_list",
  },
  {
    path: "/product/itemgroupadd",
    component: ItemGroupAdd,
    name: "Item Group Master",
    main: "Item Group",
    sub: "Add",
    link: "/product/itemgrouplist",
    securityCode: "itemgroup_master_add",
  },
  {
    path: "/product/itemgroupupdate/:id",
    component: ItemGroupAdd,
    name: "Item Group Master",
    main: "Item Group",
    sub: "Update",
    link: "/product/itemgrouplist",
    securityCode: "itemgroup_master_edit",
  },
  {
    path: "/product/itemsubgroup",
    component: ItemSubGroupMaster,
    name: "Item SubGroup Master",
    main: "Item SubGroup",
    sub: "List",
    link: "/product/itemsubgroup",
    securityCode: "itemsub_group_master_list",
  },
  {
    path: "/city",
    component: CityMaster,
    name: "City Master",
    main: "Home",
    sub: "City",
    link: "/city",
    securityCode: "ledger_list",
  },
  {
    path: "/process/processgroup/:id",
    component: ProcessSubGroup,
    name: "Process SubGroup",
    main: "Process SubGroup",
    sub: "Update",
    link: "/product/itemsubgroup",
    securityCode: "itemsub_group_master_edit",
  },
  {
    path: "/process/processaccessories/:id",
    component: ProcessSubGroupAccessories,
    name: "Process SubGroup",
    main: "Process SubGroup",
    sub: "Update",
    link: "/product/itemsubgroup",
    securityCode: "itemsub_group_master_edit",
  },
  {
    path: "/product/colormaster",
    component: ColorMaster,
    name: "Color Master",
    main: "Color",
    sub: "List",
    securityCode: "color_master_list",
  },
  {
    path: "/product/sizemaster",
    component: SizeMaster,
    name: "Size Master",
    main: "Size",
    sub: "List",
    securityCode: "size_master_list",
  },
  {
    path: "/product/processgroup",
    component: ProcessGroupMaster,
    name: "Process GroupMaster",
    main: "Process Group",
    sub: "List",
    securityCode: "process_group_master_list",
  },
  {
    path: "/product/processtype",
    component: ProcessType,
    name: "Process Type",
    main: "Process",
    sub: "List",
    securityCode: "process_type_list",
  },
  {
    path: "/product/departmentmaster",
    component: DepartmentMaster,
    name: "Department Master",
    main: "Department",
    sub: "List",
    securityCode: "department_master_list",
  },
  {
    path: "/others/vehiclemaster",
    component: VehicleMaster,
    name: "Vehicle Master",
    main: "Vehicle",
    sub: "List",
    securityCode: "vehicle_master_list",
  },
  {
    path: "/others/fuelmaster",
    component: FuelMaster,
    name: "Fuel Master",
    main: "Fuel",
    sub: "List",
    securityCode: "fueltype_master_list",
  },
  {
    path: "/others/fuelcreation",
    component: FuelCreation,
    name: "Fuel Master",
    main: "Fuel",
    sub: "Add",
    link: "/others/fuelmaster",
    securityCode: "fueltype_master_add",
  },
  {
    path: "/others/transportmaster",
    component: TransportMaster,
    name: "Transport Master",
    main: "Transport",
    sub: "List",
    securityCode: "transport_master_list",
  },
  {
    path: "/others/sectionmaster",
    component: SectionMaster,
    name: "Section Master",
    main: "Section",
    sub: "List",
    securityCode: "section_list",
  },
  {
    path: "/account/area",
    component: AreaCreation,
    name: "Area",
    main: "Area",
    sub: "List",
    securityCode: "area_creation_list",
  },
  {
    path: "/ledger/accountgroup",
    component: AccountGroup,
    name: "Account Group",
    main: "Account",
    sub: "List",
    securityCode: "account_group_list",
  },
  {
    path: "/ledger/partygroup",
    component: PartyGroup,
    name: "Party Group",
    main: "Party",
    sub: "List",
    securityCode: "party_group_list",
  },
  {
    path: "/ledger/ledgerlist",
    component: LedgerList,
    main: "Ledger",
    name: "Ledger Master",
    sub: "List",
    securityCode: "ledger_list",
  },
  {
    path: "/ledger/ledgercreation",
    component: LedgerCreation,
    name: "Ledger Master",
    main: "Ledger",
    sub: "Add",
    link: "/ledger/ledgerlist",
    securityCode: "ledger_add",
  },
  {
    path: "/ledger/update/:id",
    component: LedgerCreation,
    name: "Ledger Master",
    main: "Ledger",
    sub: "Edit",
    link: "/ledger/ledgerlist",
    securityCode: "ledger_edit",
  },
  {
    path: "/others/users",
    component: UserList,
    name: "Users",
    main: "Users",
    sub: "List",
    securityCode: "users_list",
  },
  {
    path: "/users/role/:id",
    component: UserRights,
    name: "Users",
    main: "Users",
    sub: "Role",
    link: "/others/users",
    securityCode: "users_edit",
  },
  {
    path: "/sales/list",
    component: SalesList,
    name: "Sales",
    main: "Sales",
    sub: "List",
    securityCode: "sales_list",
  },
  {
    path: "/sales/add",
    component: SalesEntry,
    name: "Sales",
    main: "Sales",
    sub: "Add",
    link: "/sales/list",
    securityCode: "sales_add",
  },
  {
    path: "/sales/update/:id",
    component: SalesEntry,
    name: "Sales",
    main: "Sales",
    sub: "Edit",
    link: "/sales/list",
    securityCode: "sales_edit",
  },
  {
    path: "/sales/invprint/:id",
    component: InvoiceTemplate,
    name: "Sales",
    main: "Sales",
    sub: "Print",
    securityCode: "sales_print",
  },
  // {
  //   path: "/sales/LetterPad/:id",
  //   component: SaleLetterInvoiceTemplate,
  //   name: "Sales",
  //   main: "Sales",
  //   sub: "Print",
  //   securityCode: "sales_print",
  // },
  {
    path: "/purchase/list",
    component: PurchaseList,
    name: "Purchase",
    main: "Purchase",
    sub: "List",
    securityCode: "purchase_list",
  },
  {
    path: "/purchase/add",
    component: PurchaseEntry,
    name: "Purchase",
    main: "Purchase",
    sub: "Add",
    link: "/purchase/list",
    securityCode: "purchase_add",
  },
  {
    path: "/purchase/update/:id",
    component: PurchaseEntry,
    name: "Purchase",
    main: "Purchase",
    sub: "Edit",
    link: "/purchase/list",
    securityCode: "purchase_edit",
  },
  {
    path: "/salesorder/list",
    component: SalesOrderList,
    name: "SalesOrder",
    main: "SalesOrder",
    sub: "List",
    securityCode: "sales_order_list",
  },
  {
    path: "/salesorder/add",
    component: SalesOrderAdd,
    name: "SalesOrder",
    main: "SalesOrder",
    sub: "Add",
    link: "/salesorder/list",
    securityCode: "sales_order_add",
  },
  {
    path: "/salesorder/update/:id",
    component: SalesOrderAdd,
    name: "SalesOrder",
    main: "SalesOrder",
    sub: "Edit",
    link: "/salesorder/list",
    securityCode: "sales_order_edit",
  },
  {
    path: "/purchaseorder/list",
    component: PurchaseOrderList,
    name: "PurchaseOrder",
    main: "PurchaseOrder",
    sub: "List",
    securityCode: "purchase_order_list",
  },
  {
    path: "/purchaseorder/add",
    component: PurchaseOrderAdd,
    name: "PurchaseOrder",
    main: "PurchaseOrder",
    sub: "Add",
    link: "/purchaseorder/list",
    securityCode: "purchase_order_add",
  },
  {
    path: "/purchaseorder/update/:id",
    component: PurchaseOrderAdd,
    name: "PurchaseOrder",
    main: "PurchaseOrder",
    sub: "Edit",
    link: "/purchaseorder/list",
    securityCode: "purchase_order_edit",
  },
  {
    path: "/StockAdjustment/list",
    component: StockAdjustmentList,
    name: "StockAdjustment",
    main: "StockAdjustment",
    sub: "List",
    securityCode: "stock_adjustment_list",
  },
  {
    path: "/StockAdjustment/add",
    component: StockAdjustmentAdd,
    name: "StockAdjustment",
    main: "StockAdjustment",
    sub: "Add",
    link: "/StockAdjustment/list",
    securityCode: "stock_adjustment_add",
  },
  {
    path: "/dc/list",
    component: DCList,
    name: "DC",
    main: "DC",
    sub: "List",
    securityCode: "dc_list",
  },
  {
    path: "/dc/add",
    component: DCAdd,
    name: "DC",
    main: "DC",
    sub: "Add",
    link: "/dc/list",
    securityCode: "dc_add",
  },
  {
    path: "/dc/update/:id",
    component: DCAdd,
    name: "DC",
    main: "DC",
    sub: "Edit",
    link: "/dc/list",
    securityCode: "dc_edit",
  },
  {
    path: "/voucher/list",
    component: VoucherList,
    name: "Voucher",
    main: "Voucher",
    sub: "List",
    securityCode: "voucher_list",
  },
  {
    path: "/voucher/",
    component: VoucherAddUpdate,
    name: "Voucher",
    main: "Voucher",
    sub: "Add",
    link: "/voucher/list",
    securityCode: "voucher_add",
  },
  {
    path: "/voucher/update/:id",
    component: VoucherAddUpdate,
    name: "Voucher",
    main: "Voucher",
    sub: "Edit",
    link: "/voucher/list",
    securityCode: "voucher_edit",
  },
  {
    path: "/journal/list",
    component: JournalList,
    name: "Journal",
    main: "Journal",
    sub: "List",
    securityCode: "journal_list",
  },
  {
    path: "/journal/add",
    component: JournalAddUpdate,
    name: "Journal",
    main: "Journal",
    sub: "Add",
    link: "/journal/list",
    securityCode: "journal_add",
  },
  {
    path: "/journal/update/:id",
    component: JournalAddUpdate,
    name: "Journal",
    main: "Journal",
    sub: "Edit",
    link: "/journal/list",
    securityCode: "journal_edit",
  },
  {
    path: "/reports/voucher/payable",
    component: VoucherPayable,
    name: "Payable",
    main: "Payable",
    sub: "List",
    securityCode: "voucher_payable",
  },
  {
    path: "/reports/voucher/receivable",
    component: VoucherReceivable,
    name: "Receivable",
    main: "Receivable",
    sub: "List",
    securityCode: "voucher_receivable",
  },
  {
    path: "/reports/voucher/statement",
    component: VoucherStatement,
    name: "Statement",
    main: "Statement",
    sub: "List",
    securityCode: "voucher_statement",
  },
  {
    path: "/reports/voucher/sales",
    component: VoucherSalesReport,
    name: "Sales Report",
    main: "Sales Report",
    sub: "List",
    securityCode: "voucher_statement",
  },
  {
    path: "/reports/voucher/party",
    component: VoucherPartyReport,
    name: "Party Report",
    main: "Party Report",
    sub: "List",
    securityCode: "voucher_statement",
  },
  {
    path: "/reports/voucher/groupwise",
    component: VoucherGroupwise,
    name: "Group Wise",
    main: "Group Wise",
    sub: "List",
    securityCode: "voucher_groupwise",
  },
  {
    path: "/reports/weeklyfinancial",
    component: WeeklyPayReceive,
    name: "Weekly Pay Receivable",
    main: "Weekly Pay Receivable",
    sub: "List",
    securityCode: "weeklyfinanacialreport",
  },
  {
    path: "/reports/pricelist",
    component: PriceList,
    name: "Price List",
    main: "PriceList",
    sub: "List",
    securityCode: "pricelist",
  },

  {
    path: "/empolyee/list",
    component: EmployeeList,
    name: "Empolyee List",
    main: "Empolyee List",
    sub: "List",
    securityCode: "employee_list",
  },
  {
    path: "/empolyee/add",
    component: EmployeeAddUpdate,
    name: "Empolyee List",
    main: "Empolyee List",
    sub: "Add",
    link: "/empolyee/list",
    securityCode: "employee_add",
  },
  {
    path: "/empolyee/update/:id",
    component: EmployeeAddUpdate,
    name: "Empolyee Update",
    main: "Empolyee Update",
    sub: "Edit",
    link: "/empolyee/list",
    securityCode: "employee_edit",
  },
  {
    path: "/worksheet/list",
    component: WorkSheetSection,
    name: "Work Sheet Section",
    main: "Work Sheet Section",
    sub: "List",
    securityCode: "worksheet_list",
  },
  {
    path: "/worksheet/view/:id",
    component: WorkSheetList,
    name: "Work Sheet List",
    main: "Work Sheet List",
    sub: "Edit",
    link: "/worksheet/list",
    securityCode: "worksheet_edit",
  },
  {
    path: "/payroll/unit/list",
    component: UnitList,
    name: "Unit List",
    main: "Unit List",
    sub: "List",
    securityCode: "payroll_unit_list",
  },
  {
    path: "/payroll/wages/list",
    component: WagesList,
    name: "Wages List",
    main: "Wages List",
    sub: "List",
    securityCode: "payroll_wages_list",
  },
  {
    path: "/reports/sales/productwise",
    component: ProductWiseSale,
    name: "Product Wise",
    main: "Product Wise",
    sub: "List",
    securityCode: "sales_productwise",
  },
  {
    path: "/reports/sales/taxwise",
    component: TaxWiseSale,
    name: "Tax Wise",
    main: "Tax Wise",
    sub: "List",
    securityCode: "sales_taxwise",
  },
  {
    path: "/reports/sales/debitnote",
    component: DebitNoteSale,
    name: "Debit Note",
    main: "Debit Note",
    sub: "List",
    securityCode: "sales_debitnote",
  },
  {
    path: "/reports/purchase/productwise",
    component: ProductWisePur,
    name: "Product Wise",
    main: "Product Wise",
    sub: "List",
    securityCode: "purchase_productwise",
  },
  {
    path: "/reports/purchase/taxwise",
    component: TaxWisePur,
    name: "Tax Wise",
    main: "Tax Wise",
    sub: "List",
    securityCode: "purchase_taxwise",
  },
  {
    path: "/reports/stock/zero",
    component: ZeroStock,
    name: "Zero Stock",
    main: "Zero Stock",
    sub: "List",
    securityCode: "stock_zero",
  },
  {
    path: "/reports/stock/detail",
    component: DetailStock,
    name: "Detail Stock",
    main: "Detail Stock",
    sub: "List",
    securityCode: "stock_detail",
  },
  {
    path: "/barcode/print",
    component: BarcodeGenerator,
    name: "Barcode Generator",
    main: "Barcode Generator",
    sub: "List",
    securityCode: "barcode_print",
  },
  {
    path: "/inward/list",
    component: InWardList,
    name: "InWard",
    main: "InWard",
    sub: "List",
    securityCode: "inward_list",
  },
  {
    path: "/inward/add",
    component: InWardAddUpdate,
    name: "InWard",
    main: "InWard",
    sub: "Add",
    link: "/inward/list",
    securityCode: "inward_add",
  },
  {
    path: "/inward/update/:id",
    component: InWardAddUpdate,
    name: "InWard",
    main: "InWard",
    sub: "Edit",
    link: "/inward/list",
    securityCode: "inward_edit",
  },
  {
    path: "/outward/list",
    component: OutWardList,
    name: "OutWard",
    main: "OutWard",
    sub: "List",
    securityCode: "outward_list",
  },
  {
    path: "/outward/add",
    component: OutWardAddUpdate,
    name: "OutWard",
    main: "OutWard",
    sub: "Add",
    link: "/outward/list",
    securityCode: "outward_add",
  },
  {
    path: "/outward/update/:id",
    component: OutWardAddUpdate,
    name: "OutWard",
    main: "OutWard",
    sub: "Edit",
    link: "/outward/list",
    securityCode: "outward_edit",
  },
  {
    path: "/fabricinward/list",
    component: FabricInWardList,
    name: "Fabric Inward",
    main: "Fabric Inward",
    sub: "List",
    securityCode: "fabricinward_list",
  },
  {
    path: "/fabricinward/add",
    component: FabricInWardAddUpdate,
    name: "Fabric Inward",
    main: "Fabric Inward",
    sub: "Add",
    link: "/fabricinward/list",
    securityCode: "fabricinward_add",
  },
  {
    path: "/fabricinward/update/:id",
    component: FabricInWardAddUpdate,
    name: "Fabric Inward",
    main: "Fabric Inward",
    sub: "Edit",
    link: "/fabricinward/list",
    securityCode: "fabricinward_edit",
  },
  {
    path: "/account/agent",
    component: AgentCreation,
    name: "Agent",
    main: "Agent",
    sub: "List",
    securityCode: "agent_list",
  },
  {
    path: "/reports/agentsalecomm",
    component: AgentSaleComm,
    name: "Agent Sale Comm",
    main: "Agent Sale Comm",
    sub: "List",
    securityCode: "agentsalecomm_report",
  },
  {
    path: "/lr/list",
    component: LrList,
    name: "L.R",
    main: "L.R",
    sub: "List",
    securityCode: "lr_list",
  },
  {
    path: "/otherbill/list",
    component: OtherBillList,
    name: "Other Bill",
    main: "Other Bill",
    sub: "List",
    securityCode: "otherbill_list",
  },
  {
    path: "/otherbill/creation",
    component: OtherBillAddUpdate,
    name: "Other Bill",
    main: "Other Bill",
    sub: "ADD",
    link: "/otherbill/list",
    securityCode: "otherbill_add",
  },
  {
    path: "/programchart/list",
    component: ProgramChartList,
    name: "Program Chart",
    main: "Program Chart",
    sub: "List",
    securityCode: "program_chart_list",
  },
  {
    path: "/programchart/creation",
    component: ProgramChartCreate,
    name: "Program Chart",
    main: "Program Chart",
    sub: "ADD",
    link: "/programchart/list",
    securityCode: "program_chart_add",
  },
  {
    path: "/reports/outstanding/balancesheet",
    component: BalanceSheet,
    name: "Balance Sheet",
    main: "Balance Sheet",
    sub: "List",
    securityCode: "outstanding_balancesheet",
  },
  {
    path: "/reports/outstanding/profitloss",
    component: ProfitLoss,
    name: "Profit and Loss",
    main: "Profit and Loss",
    sub: "List",
    securityCode: "outstanding_profitloss",
  },
  {
    path: "/reports/stock/orderstatus",
    component: OrderStatus,
    name: "Order Status",
    main: "Order Status",
    sub: "List",
    securityCode: "stock_orderstatus",
  },
  {
    path: "/reports/outstanding/ledgermonthlysummary",
    component: LedgerMonthlySummary,
    name: "Ledger Monthly Summary",
    main: "Ledger Monthly Summary",
    sub: "List",
    securityCode: "outstanding_ledgermonthly",
  },
];

export default routes;
