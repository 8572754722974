import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";

export const ChangeToValidNumber = (value, type) => {
  let converted = parseFloat(value).toFixed(2);

  if (type && type == "number") {
    converted = parseInt(converted).toFixed(0);
  }
  return isNaN(converted)
    ? type && type == "number"
      ? "0"
      : "0.00"
    : converted;
};

export const ShowMessage = (type, message) => {
  return toast[type](message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const CalculateTotal = (array, param, type) => {
  if (array && array.length > 0) {
    let data = [...array];
    return ChangeToValidNumber(
      data
        .reduce(
          (total, currentValue) =>
            (total =
              total +
              (currentValue && currentValue[param]
                ? parseFloat(currentValue[param].toString().replace(",", ""))
                : 0)),
          0
        )
        .toFixed(2),
      type
    );
  } else {
    return 0.0;
  }
};

export const ArraySearch = (array, searchText, searchItems) => {
  var lowSearch = searchText.toLowerCase();
  return array.filter((value) =>
    searchItems.some((key) =>
      String(value[key]).toLowerCase().includes(lowSearch)
    )
  );
};

export const DateSearch = (array, searchItems, startDate, endDate) => {
  return array.filter((value) =>
    searchItems.some(
      (key) =>
        // String(value[key]).toLowerCase().includes(lowSearch)
        value[key].getTime() <= endDate && value[key].getTime() >= startDate
    )
  );
};

export const DateConvert = (date, format) => {
  return moment(date).format(format);
};

export const CommaToFixed = (value) => {
  return value ? Math.abs(parseFloat(value.replace(/,/g, ""))).toFixed(2) : 0.0;
};

export const GetUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};
export const Confirmation = (
  title,
  text,
  icon,
  buttons,
  dangerMode,
  content
) => {
  return swal({
    title: title,
    text: text,
    icon: icon,
    buttons: buttons ? buttons : null,
    dangerMode: dangerMode,
    content: content ? content : null,
  });
};

export const securityCheck = (submenu, rightArray) => {
  if (submenu.children && submenu.children.length > 0) {
    let securityCodeAvailable = false;
    submenu.children.map((subChild) => {
      if (rightArray.find((right) => right == subChild.securityCode)) {
        securityCodeAvailable = true;
      }
    });
    return securityCodeAvailable;
  } else {
    return rightArray.find((right) => right == submenu.securityCode);
  }
};

let UserSecurity = JSON.parse(localStorage.getItem("userInfo"));

let rightArray = [];
if (UserSecurity?.user_right) {
  rightArray = UserSecurity?.user_right.split(",");
}

export const userPermissionCheck = (value) => {
  return rightArray.find((right) => right == value);
};

export const NumberToWords = (input) => {
  /* eslint-disable eqeqeq */

  const a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  const b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  const regex = /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/;

  const getLT20 = (n) => a[Number(n)];
  const getGT20 = (n) => b[n[0]] + " " + a[n[1]];

  const num = Number(input);
  if (isNaN(num)) return "";
  if (num === 0) return "zero";

  const numStr = num.toString();
  if (numStr.length > 9) {
    throw new Error("overflow"); // Does not support converting more than 9 digits yet
  }

  const [, n1, n2, n3, n4, n5] = ("000000000" + numStr).substr(-9).match(regex); // left pad zeros

  let str = "";
  str += n1 != 0 ? (getLT20(n1) || getGT20(n1)) + "crore " : "";
  str += n2 != 0 ? (getLT20(n2) || getGT20(n2)) + "lakh " : "";
  str += n3 != 0 ? (getLT20(n3) || getGT20(n3)) + "thousand " : "";
  str += n4 != 0 ? getLT20(n4) + "hundred " : "";
  str += n5 != 0 && str != "" ? "and " : "";
  str += n5 != 0 ? getLT20(n5) || getGT20(n5) : "";

  return str.trim();
};

export const numberFormat = (value) =>
  value.toLocaleString("en-IN", {
    maximumFractionDigits: 2,
  });
// new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(value);
