import React from "react";
import { Link } from "react-router-dom";

const AppBreadcrumb = ({ crumbs }) => {
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          {crumbs.map(({ name, main, sub, link }, key) =>
            key + 1 === crumbs.length ? (
              <React.Fragment key={key}>
                <h4 className="mb-sm-0">{name}</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to={link || "/"}>{main}</Link>
                    </li>
                    <li className="breadcrumb-item active">{sub}</li>
                  </ol>
                </div>
              </React.Fragment>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

export default AppBreadcrumb;
