import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { securityCheck } from "./Utils";

const SidebarItem = ({ submenu, pathName, rightArray }) => {
  const location = useLocation();
  const history = useHistory();

  const RedirectionHandler = (path) => {
    history.push(path);
    if (document.body.classList.contains("vertical-sidebar-enable")) {
      document.body.classList.remove("vertical-sidebar-enable");
    }
  };

  if (submenu.children) {
    return (
      <ul className="nav nav-sm flex-column">
        <li className="nav-item">
          <a
            href={`#${submenu.title}`}
            className={`nav-link ${
              location.pathname.includes(submenu.path) ? "active" : ""
            }`}
            data-bs-toggle="collapse"
            role="button"
            aria-expanded={
              location.pathname.includes(submenu.path) ? true : false
            }
            aria-controls={submenu.title}
            data-key="t-ecommerce"
          >
            {submenu.name}
          </a>
          <div
            className={`collapse menu-dropdown ${
              location.pathname.includes(submenu.path) ? "show" : ""
            }`}
            id={submenu.title}
          >
            {submenu.children.map((child, index) => {
              if (securityCheck(child, rightArray)) {
                return <SidebarItem key={index} submenu={child} pathName="" />;
              }
            })}
          </div>
        </li>
      </ul>
    );
  } else {
    return (
      <ul className="nav nav-sm flex-column">
        <li className="nav-item" key={submenu.id}>
          <a
            href={`#${submenu.path}`}
            onClick={RedirectionHandler.bind(this, submenu.path)}
            className={`nav-link cursor-pointer ${
              submenu.path.replace("#", "") === location.pathname.toLowerCase()
                ? "active"
                : ""
            }`}
            data-key="t-calendar"
          >
            {submenu.name}{" "}
          </a>
        </li>
      </ul>
    );
  }
};

export default SidebarItem;
